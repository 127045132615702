import { Route, Switch } from 'react-router-dom';

import Home from "./pages/Home"
import About from "./pages/About"
import Menu from "./pages/Menu"
import Gallery from "./pages/Gallery"
import Contact from "./pages/Contact"

import FooterComp from "./components/footer"
import Bottles from './pages/Bottles';
import Cocktails from './pages/Cocktails';

export default function App() {
  return (
    <div>
      <Switch>
        <Route path="/" exact>
          <Home/>
        </Route>
        <Route path="/about">
          <About/>
        </Route>
        <Route path="/menu">
          <Menu/>
        </Route>
        <Route path="/menu-cocktails">
          <Cocktails/>
        </Route>
        <Route path="/menu-bottles">
          <Bottles/>
        </Route>
        <Route path="/gallery">
          <Gallery/>
        </Route>
        <Route path="/contact">
          <Contact/>
        </Route>
      </Switch>
      <FooterComp />
    </div>
  );
}
