import "./index.css"

export default function BottlesComp() {
    return (
        <div className="box">
            <div className="d-flex bottles-menu">
                <div style={{ marginInline: "50px" }}>
                    <img style={{ width: "12vh" }} src="./assets/images/bottles-1.png" alt="bottles-1" />
                </div>
                <div style={{ marginInline: "50px" }}>
                    <img style={{ width: "12vh" }} src="./assets/images/bottles-2.png" alt="bottles-2" />
                </div>
                <div style={{ marginInline: "50px" }}>
                    <img style={{ width: "12vh" }} src="./assets/images/bottles-3.png" alt="bottles-3" />
                </div>
                <div style={{ marginInline: "50px" }}>
                    <img style={{ width: "8vh" }} src="./assets/images/bottles-4.png" alt="bottles-4" />
                </div>
                <div style={{ marginInline: "50px" }}>
                    <img style={{ width: "12vh" }} src="./assets/images/bottles-5.png" alt="bottles-5" />
                </div>
            </div>
        </div>
    )
}