import CocktailsComp from "../components/menu/cocktails";
import NavbarComp from "../components/navbar";

export default function Cocktails() {
  return (
    <>
      <NavbarComp />
      <CocktailsComp />
    </>
  );
}
