import "./index.css";

export default function CocktailsComp() {
  return (
    <div className="box">
      <div className="d-flex cocktails-menu">
        <div>
          <img
            src="./assets/images/cocktails-1.png"
            alt="cocktails-1"
            style={{ width: "26vh", marginInline: "50px" }}
          />
        </div>
        <div>
          <img
            src="./assets/images/cocktails-2.png"
            alt="cocktails-2"
            style={{ width: "26vh", marginInline: "50px" }}
          />
        </div>
        <div>
          <img
            src="./assets/images/cocktails-3.png"
            alt="cocktails-3"
            style={{ width: "16vh", marginInline: "50px", marginTop: "40px" }}
          />
        </div>
      </div>
    </div>
  );
}
