import { useHistory, useLocation } from "react-router-dom";

import "./index.css";

export default function NavbarComp() {
  const history = useHistory();
  const location = useLocation();

  return (
    <div
      className="box-nav"
      style={
        location.pathname === "/menu-cocktails" ||
          location.pathname === "/menu-bottles"
          ? { height: "29vh" }
          : { height: "25vh" }
      }
    >
      <div role="button" onClick={() => history.push("/")} className="d-flex justify-content-center title-nav">
        <img src="./Logo.png" alt="logo" width="125px" />
      </div>
      <div className="menu-nav">
        <div>
          <div
            role="button"
            onClick={() => history.push("/about")}
            className="item-nav"
          >
            ABOUT
          </div>
          <div
            className={
              location.pathname === "/about" ? "dot-active" : "d-none"
            }
          ></div>
        </div>
        <div className="text-center">
          <div
            role="button"
            onClick={() => history.push("/menu")}
            className="item-nav"
          >
            MENU
          </div>
          <div
            className={
              location.pathname === "/menu" ||
                location.pathname === "/menu-cocktails" ||
                location.pathname === "/menu-bottles"
                ? "dot-active"
                : "d-none"
            }
          ></div>
          <div className={location.pathname === "/menu-cocktails" || location.pathname === "/menu-bottles" ? "item-has-sub" : "d-none"}>
            <div
              role="button"
              onClick={() => history.push("/menu-cocktails")}
              style={
                location.pathname === "/menu-cocktails"
                  ? { textDecoration: "underline" }
                  : { textDecoration: "none" }
              }
              className="sub-item-nav"
            >
              COCKTAILS
            </div>
            <div
              role="button"
              onClick={() => history.push("/menu-bottles")}
              style={
                location.pathname === "/menu-bottles"
                  ? { textDecoration: "underline" }
                  : { textDecoration: "none" }
              }
              className="sub-item-nav"
            >
              BOTTLES
            </div>
          </div>
        </div>
        <div>
          <div
            role="button"
            onClick={() => history.push("/gallery")}
            className="item-nav"
          >
            GALLERY
          </div>
          <div
            className={
              location.pathname === "/gallery" ? "dot-active" : "d-none"
            }
          ></div>
        </div>
        <div>
          <div
            role="button"
            onClick={() => history.push("/contact")}
            className="item-nav"
          >
            CONTACT
          </div>
          <div
            className={
              location.pathname === "/contact" ? "dot-active" : "d-none"
            }
          ></div>
        </div>
      </div>
    </div>
  );
}
