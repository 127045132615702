import "./index.css";

export default function FooterComp() {
  return (
    <div className="footer">
      <div className="d-flex justify-content-center img-box">
        <img src="./assets/images/dots.png" alt="footer" className="img-dots" />
      </div>
      <div className="text-center text-footer">
        Copyright © 2022 - TRUCE, All Rights Reserved.
      </div>
    </div>
  );
}
