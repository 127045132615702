import BottlesComp from "../components/menu/bottles";
import NavbarComp from "../components/navbar";

export default function Bottles() {
  return (
    <>
      <NavbarComp />
      <BottlesComp />
    </>
  );
}
