import ContactComp from "../components/contact";
import NavbarComp from "../components/navbar";

export default function Contact() {
  return (
    <>
      <NavbarComp />
      <ContactComp />
    </>
  );
}
