import "./index.css";

export default function AboutComp() {
  return (
    <div className="box about-content">
      <>
        <div className="mb-2">
          Tucked away in the basement of an office building, TRUCE serves as a
          hidden joint for our patrons to connect and share their love for fine
          drinks and conversation. With a sleek and elegant interior, patrons
          are greeted with a warm and intimate atmosphere as soon as they walk
          in.
        </div>
        <div className="mb-2">
          With an emphasis on superior hospitality, employees are expected to
          provide personallized service at the bar. In addition to crafted and
          signature cocktails, TRUCE serves unrivaled assortment of premium
          spirits, ranging from whisky, gin, cognac, and brandy to rum and
          tequila.
        </div>
        TRUCE also serves flight tastings for single malt whisky, for patrons
        who enjoy the finer things in life. TRUCE is the perfect place for wise,
        old souls; serving jazz, soul and blues music from well known golden-era
        singers and performers, it provides a complete experience for patrons to
        enjoy their after hours.
      </>
    </div>
  );
}
