import { Navigation } from "swiper";
import { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";
import "./index.css";

export default function GalleryComp() {
  const [data, setData] = useState([]);

  const generateData = () => {
    let temp = [];
    for (let i = 1; i <= 16; i++) {
      const image = "./assets/images/gallery/image" + i + ".jpg";
      temp.push(image);
    }
    setData(temp);
  };

  useEffect(() => {
    generateData();
  }, []);

  return (
    <div className="box gallery-content">
      <Swiper
        navigation={{
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        }}
        modules={[Navigation]}
        className="mySwiper gallery-image"
      >
        {data.map((e, i) => (
          <SwiperSlide key={i}>
            <img src={e} alt="detail" style={{ height: "45vh", width: "70vh", objectFit: "cover", marginInline: "50px" }}/>
          </SwiperSlide>
        ))}
        <div className="swiper-button-prev"></div>
        <div className="swiper-button-next"></div>
      </Swiper>
      <Swiper className="mySwiper-mobile gallery-image-mobile">
        {data.map((e, i) => (
          <SwiperSlide key={i}>
            <img src={e} alt="detail" style={{ height: "60vw", width: "75vw", objectFit: "cover"}}/>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}
