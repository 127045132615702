import GalleryComp from "../components/gallery";
import NavbarComp from "../components/navbar";

export default function Gallery() {
  return (
    <>
      <NavbarComp />
      <GalleryComp />
    </>
  );
}
