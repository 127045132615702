import "./index.css";

export default function ContactComp() {
  return (
    <div className="box contact-content">
      <div className="d-flex justify-content-center">
        {/* <div className="text-content text-center">
          <div className="title-text-content">ADDRESS</div>
          <div className="text-contact">JALAN SINDANG SIRNA NO 38 BANDUNG, 40153</div>
        </div> */}
        <div className="text-content text-center">
          <div className="title-text-content">EMAIL</div>
          <div className="text-contact">bar.truce@gmail.com</div>
        </div>
        <div className="text-content text-center">
          <div className="title-text-content">TELEPHONE</div>
          <div className="text-contact">+62 878 7778 7474</div>
        </div>
        <div className="text-content text-center">
          <div className="title-text-content">INSTAGRAM</div>
          <div className="text-contact">@bartruce</div>
        </div>
      </div>
      <div className="form-contact-content">
        <div className="d-flex justify-content-center flex-column">
          <iframe
            src="https://maps.google.com/maps?q=jl%20sindang%20sirna%20no%2038&t=&z=13&ie=UTF8&iwloc=&output=embed"
            style={{ border: "0", width: "40vh", height: "30vh" }}
            allowFullScreen=""
            loading="lazy"
            title="map"
          ></iframe>
          <div className="text-contact mt-2">JALAN SINDANG SIRNA NO 38 BANDUNG, 40153</div>
        </div>
        <div className="form-reservation">
          <div className="title-form">RESERVATION</div>
          <div>
            <input
              name="name"
              placeholder="Name"
              className="form-input-reservation form-control"
            />
            <hr className="mt-0" style={{ color: "black" }} />
          </div>
          <div>
            <input
              name="phone"
              placeholder="Phone"
              className="form-input-reservation form-control"
            />
            <hr className="mt-0" style={{ color: "black" }} />
          </div>
          <div className="btn-reservation">
            <a href="https://api.whatsapp.com/send?phone=+6287877787474&text=Hello,%20I%20would%20like%20to%20reserve%20a%20seat%20please.">
              <div className="btn btn-dark">RESERVE NOW</div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
