import { useHistory } from "react-router-dom"

import "./index.css"

export default function MenuComp () {
    const history = useHistory()

    return (
        <div className="box menu-content">
            <div className="menu-box">
                <div role="button" onClick={() => history.push("/menu-cocktails")} className="menu-item">COCKTAILS</div>
                <div role="button" onClick={() => history.push("/menu-bottles")} className="menu-item">BOTTLES</div>
            </div>
        </div>
    )
}