import { useHistory } from "react-router-dom";

import "./index.css"

export default function HomeComp() {
    const history = useHistory()
    return (
        <div className="box-home">
            <div className="d-flex justify-content-center title-home">
                <img src="./Logo.png" alt="logo" width="125px" />
            </div>
            <div className="menu-home">
                <div role="button" onClick={() => history.push("/about")} className="item">ABOUT</div>
                <div role="button" onClick={() => history.push("/menu")} className="item">MENU</div>
                <div role="button" onClick={() => history.push("/gallery")} className="item">GALLERY</div>
                <div role="button" onClick={() => history.push("/contact")} className="item">CONTACT</div>
            </div>
        </div>
    )
}