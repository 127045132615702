import AboutComp from "../components/about";
import NavbarComp from "../components/navbar";

export default function About() {
  return (
    <>
      <NavbarComp />
      <div className="box-content">
        <AboutComp />
      </div>
    </>
  );
}
